@keyframes bounce {
  from {
    transform: scale(1.05) translateY(0px);
  }
  to {
    transform: scale(1.1) translateY(-15px);
  }
}

.poke-ball {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  width: 270px;
  height: 270px;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 30px;
}

.poke-ball:hover {
  animation: bounce 0.75s infinite alternate;
}

.poke-ball-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 25px;
  align-items: center;
  color: white;
  grid-area: 1 / 1 / 3 / 5;
  background-color: var(--main-color);
  width: 100%;
}

.poke-ball-bottom {
  grid-area: 3 / 1 / 5 / 5;
  background-color: white;
  width: 100%;
}

.ava-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 2 / 2 / 4 / 4;
  z-index: 1;
}

.poke-ava {
  width: 120px;
  height: 120px;
  border: 3px solid #fff;
  background-color: var(--main-color-blue);
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  font-size: 0;
}

.poke-ball-name {
  font-size: 1.17em;
}

.poke-ball-number {
  font-size: 0.67em;
}
