@keyframes bounce {
  from {
    transform: scale(1.05) translateY(0px);
  }
  to {
    transform: scale(1.1) translateY(-15px);
  }
}

.poke-evo-tree {
  display: flex;
  justify-content: center;
}

.poke-evo-wrapper {
  display: flex;
  align-items: center;
}

.poke-evo-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
}

.poke-evo-parent > img:hover {
  animation: bounce 0.75s infinite alternate;
}

.poke-evo-children-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
