#modal {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 100%;
  width: calc(100% - 20px);
  height: 100%;
  background-color: white;
  opacity: 0;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
  overflow-y: auto;
  transition: 1s ease;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

#modal.active {
  opacity: 100;
  transform: translateX(-100%);
}

#modal-content {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
}

#modal-close-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 25px;
  left: calc(100% - 55px);
  z-index: 5;
  padding: 0;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 0;
  width: 42px;
  height: 42px;
  background: none;
  cursor: pointer;
}

.modal-button-bar {
  stroke: rgb(221, 221, 221);
  stroke-width: 7px;
}

@media all and (min-width: 768px) {
  #modal {
    left: 0;
    top: 100%;
  }
  #modal.active {
    transform: translateY(-100%);
  }
  .modal-button-bar {
    stroke: white;
  }
}
