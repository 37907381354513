#page-container.pokemons-list {
  align-items: center;
}

#pokemon-search-bar-wrapper {
  position: sticky;
  top: 0;
  background-color: var(--main-color);
  display: flex;
  padding: 10px;
  transition: 0.3s ease;
  z-index: 2;
}

#pokemon-search-bar {
  font-family: "Bungee";
  flex: 1;
  font-size: 1rem;
  border: 0;
  border-radius: 5px;
  height: 50px;
  padding-left: 20px;
}

@media all and (min-width: 576px) {
  #page-container.pokemons-list {
    align-items: flex-start;
  }
}
