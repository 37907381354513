.poke-details-container {
  background-color: white;
  max-width: 600px;
  margin: 0 auto 10px;
  overflow: hidden;
  border-radius: 15px;
  flex: 1;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
}

.details-modal-header {
  color: white;
  color: #fff;
  text-align: center;
  padding: 15px;
  font-size: 24px;
  font-weight: lighter;
}

.details-modal-content {
  background-color: white;
  padding: 16px;
}

.details-profile-overview {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  justify-items: center;
  margin: 0;
}

.poke-bio {
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  text-align-last: center;
  padding: 0 10px;
}

.details-stats {
  margin: 0 auto;
}

.poke-number-details {
  font-size: large;
  grid-area: 1 / 1 / 2 / 2;
}

.poke-types-details {
  display: flex;
}

.poke-type {
  padding: 0 5px 15px;
  font-size: larger;
}

.details-1st-child > img {
  width: 110px;
  height: 110px;
}

.poke-moves-detail-list {
  width: 100%;
  padding-inline-start: 0;
}

.poke-move-detail {
  margin: 2px 0;
  display: flex;
}

.poke-move-name {
  flex-basis: 50%;
}

.details-section-header {
  color: white;
  text-align: center;
  margin: 20px 0;
  font-size: 20px;
}

.details-profile {
  display: flex;
  padding: 0 10px 0;
}

.details-profile > div {
  flex: 1;
}

.details-profile > div > div {
  margin-bottom: 5px;
}

.one-liner {
  white-space: pre;
}

@media all and (min-width: 576px) {
  .details-overview-and-stats {
    display: flex;
  }

  .poke-bio > p {
    margin-top: 5px;
  }

  .details-profile-overview {
    flex: 1;
    justify-items: left;
  }

  .details-stats {
    flex: 1;
  }

  .poke-number-details {
    grid-area: 2 / 1 / 3 / 2;
    padding-left: 5px;
  }

  .poke-types-details {
    grid-area: 1 / 1 / 2 / 2;
  }

  .poke-type {
    padding: 0 5px 0;
  }
}
