@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Montserrat&display=swap");

:root {
  --main-color: #dd1414;
  --secondary-color: #a10000;
  --main-color-blue: #3b4cca;
  --main-color-yellow: #ffde00;
  --secondary-color-yellow: #b3a125;
}

/* Remove outline for non-keyboard :focus */
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

html {
  line-height: 1.2;
}

body.freeze-page,
html.freeze-page {
  overflow: hidden;
  height: 100%;
  position: relative;
}

body {
  background-color: #8da098;
  margin: 0 auto;
  font-family: "Bungee";
  font-size: small;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.25rem 0;
}

button {
  background-color: var(--main-color);
  color: white;
  font-size: 1.25rem;
  border: none;
  border-radius: 15px;
  padding: 0.35rem;
  text-align: center;
  cursor: pointer;
}

button:active {
  transform: translateY(2px);
}

table,
th,
td {
  border: none;
}

table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}

thead {
  border-bottom: 1px solid #d0d0d0;
}

td,
th {
  padding: 15px 5px;
  display: table-cell;
  vertical-align: middle;
  border-radius: 2px;
}

#title-bar {
  background-color: var(--secondary-color);
  color: white;
  font-size: 1.75rem;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  text-transform: capitalize;
}

#logo {
  height: 80%;
}

#main-nav {
  position: fixed;
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: var(--main-color);
  width: 15rem;
  top: 0;
  left: -15rem;
  opacity: 0;
  transition: 0.6s ease;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

#main-nav.active {
  transform: translateX(100%);
  opacity: 100;
}

#toggle-nav-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 3;
  padding: 15px;
  font-size: 30px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: var(--secondary-color);
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

#toggle-nav-button.active {
  background-color: var(--main-color);
  border: none;
  box-shadow: none;
}

#toggle-nav-button > div:nth-child(2) {
  margin: 7px 0 7px 0;
}

#toggle-nav-button.active > div:nth-child(1) {
  top: 10px;
  transform: rotate(45deg);
  background-color: white;
}

#toggle-nav-button.active > div:nth-child(2) {
  display: none;
  background-color: white;
}

#toggle-nav-button.active > div:nth-child(3) {
  top: 6px;
  transform: rotate(135deg);
  background-color: white;
}

#page-container {
  min-height: calc(100vh - 16px);
  padding: 20px 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#spinner-container {
  z-index: 10;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(208, 208, 208, 0.3);
}

#spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -40px 0 0 -40px;
  border: 10px solid var(--secondary-color);
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 1.5s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#auckland {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

#logo {
  cursor: pointer;
}

.big-title,
.big-subtitle {
  text-align: center;
}

.big-title {
  margin-top: 1rem;
}

.nav-item {
  text-align: center;
  color: white;
  font-size: 2.5rem;
  transition: 0.1s ease;
  transition-property: transform;
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: none;
  overflow: hidden;
}

.nav-item.active,
.nav-item:hover {
  background-color: var(--secondary-color);
  transform: scale(1.05);
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
}

.nav-button-bar {
  display: block;
  background-color: white;
  position: relative;
  width: 100%;
  height: 4px;
  transition: 1s ease;
}

.card-section {
  /* min-height: 266px; */
  width: 100%;
  background-color: white;
  margin-bottom: 36px;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
}

.card-section.profile {
  margin-top: 65px;
  display: flex;
  flex-direction: column;
}

.card-section:hover {
  box-shadow: 0 2.5px 2.5px 0 rgba(60, 64, 67, 0.08),
    0 2.5px 4.5px 2.5px rgba(60, 64, 67, 0.16);
}

.card-title {
  /* min-height: 45px; */
  padding: 8px 13px 8px 13px;
  margin: 0;
  background-color: var(--main-color);
  color: #ffffff;
  border-radius: 5px 5px 0 0;
  position: relative;
}

.card-title.clickable {
  cursor: pointer;
}

.card-title.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.card-content {
  padding: 10px;
}

.ava {
  margin-top: -50px;
  width: 100px;
  height: 100px;
  border: 3px solid #fff;
  background-color: #3b4cca;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
}

.profile-title {
  background-color: var(--main-color);
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  border-radius: 5px 5px 0 0;
  justify-content: flex-start;
  text-align: center;
}

.profile-content {
  padding: 10px;
  flex: 1 1 auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.profile-content > *:not(:first-child) {
  margin: 10px 0 0 0;
}

.vcard-button {
  background-color: var(--main-color);
  color: white;
  width: 100%;
  font-size: 18px;
  border: none;
  border-radius: 15px;
  padding: 3px;
  text-align: center;
}

.comment {
  margin-bottom: 15px;
}

.comment-author {
  font-size: 1rem;
  color: #999;
  padding: 0 0 3px 2px;
}

.comment-content {
  text-align: left;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 15px;
  background-color: #f1f0f0;
}

#search-box {
  outline: none;
  resize: none;
  background-color: #ffffff;
  border-style: solid;
  border-color: #e2e2e2;
  border-radius: 5px;
  padding: 7px;
  height: 50px;
  overflow-y: auto;
  margin: 0 10px 10px;
  font-size: xx-large;
}

#search-box::placeholder {
  font-size: small;
}

#timetable {
  width: 100%;
  text-align: center;
}

@media all and (min-width: 576px) {
  body {
    font-size: medium;
  }
  #page-container {
    min-height: calc(100vh - 50px);
    padding: 50px 40px 0 40px;
  }
  #main-nav {
    width: 25rem;
    left: -25rem;
  }
  .nav-item {
    font-size: 3.5rem;
  }
  #page-container.grid-on-lg {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 55px;
    justify-items: center;
  }
  #search-box::placeholder {
    font-size: large;
  }
}
